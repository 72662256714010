<template>
  <v-card
    :dark="dark"
    :class="color"
    rounded="lg"
    elevation="2"
    min-height="180"
  >
    <v-card-text>
      <div class="d-flex justify-space-between">
        <h6
          :class="{
            'text-subtitle-1': true,
            'font-weight-bold': true,
            'white--text': dark,
          }"
        >
          {{ title }}
        </h6>
        <v-icon large>
          {{ icon }}
        </v-icon>
      </div>
      <span :class="{ 'text-h3': true, 'white--text': dark }">{{
        amount
      }}</span>
      <div
        v-if="active"
        class="d-flex justify-space-between"
      >
        <h6 :class="{ overline: true, 'white--text': dark }">Ativos</h6>
        <span :class="{ 'white--text': dark }">{{ active }}</span>
      </div>
      <div
        v-if="inactive"
        class="d-flex justify-space-between"
      >
        <h6 :class="{ overline: true, 'white--text': dark }">Inativos</h6>
        <span :class="{ 'white--text': dark }">{{ inactive }}</span>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    dark: {
      type: Boolean,
      default: false,
    },
    color: String,
    title: String,
    icon: String,
    amount: String,
    active: String,
    inactive: String,
  },
}
</script>

<style scoped lang="scss">
.primary-color {
  background: linear-gradient(90deg, #ec6a23, #e64a19);
}
.success-color {
  background: linear-gradient(90deg, #54eb47, #33a728);
}
.warning-color {
  background: linear-gradient(90deg, #b97e31, #f18900);
}
.warning2-color {
  background: linear-gradient(90deg, #bbad2f, #f3dc0d);
}
.accent-color {
  background: linear-gradient(90deg, #0f5baa, #0e4a8b);
}
.total-color {
  background: linear-gradient(90deg, #9cb931, #aedd02);
}
.error-color {
  background: linear-gradient(90deg, #eb4747, #bd3636);
}
</style>
